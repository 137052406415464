export const firebaseConfig = {
    apiKey: "AIzaSyDVKMmbkLOHEPIhiDwKm1bto-VEeqgvjnA",
    authDomain: "vites-tips.firebaseapp.com",
    databaseURL: "https://vites-tips.firebaseio.com",
    projectId: "vites-tips",
    storageBucket: "vites-tips.appspot.com",
    messagingSenderId: "399277953253",
    appId: "1:399277953253:web:e81f2b1daecc4d589c840c",
    measurementId: "G-3HDG9YHX5V"
};

/**
 * let app = firebase.initializeApp(firebaseConfig);
 * let auth = firebase.auth();
 * let db = firebase.firestore();
 */
