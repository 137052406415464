const errorMessages = {
    ita: {
        title: 'Questa è una pagina di errore',
        goBack: 'Torna alla home',
        404: 'Pagina non trovata!',
        500: 'Errore interno!',
    },
    eng: {
        title: 'This is an error',
        goBack: 'Return to the home page',
        404: 'Page not found!',
        500: 'Internal error!',
    },
    deu: {
        title: 'This is an error',
        goBack: 'Return to the home page',
        404: 'Page not found!',
        500: 'Internal error!',
    },
}

const errorHTML = '' +
    '<div class="card m-3 text-center">' +
    '    <div class="card-body">' +
    '        <h1 class="card-title"><strong> Oops! </strong></h1>' +
    '        <h5><strong> {{thisIsAnError}} </strong></h5>' +
    '        <p> {{msg}} </p>' +
    '        <span class="local-link" data-src="{{h}}:{{cl}}"><u> {{goBack}} </u></span>' +
    '    </div>' +
    '</div>'

export function errorPage(code: '404' | '500', lang: string): string | null {
    if (!['ita', 'eng', 'deu'].includes(lang))
        lang = 'ita';

    return errorHTML
        .replace(/{{thisIsAnError}}/g, errorMessages[lang].title)
        .replace(/{{msg}}/g, errorMessages[lang][code])
        .replace(/{{goBack}}/g, errorMessages[lang].goBack);
}
