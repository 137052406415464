import {firebaseConfig} from "./firebase-config";

import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import {errorPage} from "./errors";

firebase.initializeApp(firebaseConfig);
let app = firebase.app();
let db = app.firestore();

export interface LocalLinkElement extends HTMLElement {
    dataset: {
        src: string
    }
}

export interface PageHash {
    pageID: string;
    lang: string;
}

const pages = {
    home: 'oZGw0WifDbztzQpmdMud'
}


if (!pageHash().pageID || !pageHash().lang)
    window.location.hash = buildURL(pages.home, 'ita');


function loadPage() {
    let pageID: string = pageHash().pageID;
    let lang: string = pageHash().lang;

    const content = document.getElementById('content');

    db.collection('pagesHTML').doc(pageID)
        .get()
        .then((doc: firebase.firestore.DocumentSnapshot) => {
            const docData = doc.data();
            if (docData) {
                const html: string = docData.body[lang];
                content.innerHTML = html ? replaceVars(pageID, lang, html) : errorPage('404', lang);
            } else {
                content.innerHTML = errorPage('404', lang);
            }
            replacePageHTMLVars(pageID, lang);
        })
        .catch((error) => {
            console.error(error);
            /**
             * TODO: send 500 page
             */
            content.innerHTML = errorPage('404', lang);
            replacePageHTMLVars(pageID, lang);
        });
}

function replaceVars(pageID: string, lang: string, html: string): string {
    return html
        .replace(/{{h}}/g, pages.home) // home
        .replace(/{{cp}}/g, pageID) // currentPage
        .replace(/{{cl}}/g, lang); // currentLang
}

function replacePageHTMLVars(pageID: string, lang: string) {
    let links = document.getElementsByClassName('local-link');

    for (let i = 0; i < links.length; i++) {
        const link: LocalLinkElement = <LocalLinkElement>links[i];
        const url = replaceVars(pageID, lang, link.dataset.src);


        link.onclick = () => {
            window.location.hash = buildURL(
                parseLocalLink(url).pageID,
                parseLocalLink(url).lang
            );
        }
    }
}

function buildURL(page: string, lang: string): string {
    return [page, lang].join(':');
}

function pageHash(): PageHash {
    const hash: string = window.location.hash.slice(1);
    return {
        pageID: hash.split(':')[0],
        lang: hash.split(':')[1]
    }
}

function parseLocalLink(link: string): PageHash {
    return {
        pageID: link.split(':')[0],
        lang: link.split(':')[1]
    }
}

window.onhashchange = loadPage;
loadPage();
